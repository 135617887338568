
import { Error401Component } from './shared/components/error-pages/error401/error401.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreloadAllModules } from '@angular/router';
import { Error404Component } from './shared/components/error-pages/error404/error404.component';
import { CustomPreloadingModuleService } from './shared/services/custom-preloading-module.service';
import { LoginGuard } from './shared/guards/login.guard';

const routes: Routes = [

  {
    path: 'login',
    data: { preload: true },
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'free-trial',
    data: { preload: true },
    loadChildren: () => import('./modules/free-trial/free-trial.module').then(m => m.FreeTrialModule)
  },

  {
    path: 'registration',
    data: { preload: true },
    loadChildren: () => import('./modules/user-registration-form/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'workspace',
    canActivate: [LoginGuard],
    loadChildren: () => import('./modules/workspace/workspace.module').then(m => m.WorkspaceModule)
  },
  {
    path: 'document',
    loadChildren: () => import('./modules/document-detailed-view/document-detailed-view.module').then(m => m.DocumentDetailedViewModule)
  },
  {
    path: 'styles',
    loadChildren: () => import('./modules/style-library/style-library.module').then(m => m.StyleLibraryModule)
  },


  { path: 'error-401', component: Error401Component },
  { path: 'error-404', component: Error404Component },
  { path: '', redirectTo: '/login', pathMatch: 'full' },

  { path: '**', redirectTo: '/error-404', pathMatch: 'full' }
];/*  */

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        useHash: false,
        // preloadingStrategy: PreloadAllModules
        preloadingStrategy: CustomPreloadingModuleService
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
